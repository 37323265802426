import Vue from 'vue';
import VueRouter from 'vue-router';

// 重写路由push方法 ---> vue-cli4.x的坑
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};
Vue.use(VueRouter);
// 路由规则：菜单固定只添加两层，路径可添加三层，分别对应菜单、功能、页面，
// 例如：客户管理/客户列表   客户列表的路径固定为customer/client   详情页面可在此功能页面添加三层页面
// 移动端将在此路径的基础上，增加手机标识/mobile在最前
// 加载路由
import WebRouter from "@/router/webRouter";
import QywxMobileRouter from "@/router/QywxMobileRouter";
import SaasPlatRouter from "@/router/SaasPlatRouter";
const baseRouters = [
    {path: '/', name: '登录', component: () => import("../views/h5/login/MerchantLogin")},
    {path: '/useradmin', name: 'saas登录', component: () => import("../views/h5/login/SaasLogin")},
    {path: '/login/qywxcode', name: '企业微信code', component: () => import("../views/h5/login/QywxLogin.vue")},
    {path: '/login/qywxbind', name: '企业微信绑定', component: () => import("../views/h5/login/QywxBindEmployee.vue")},
    {path: '/login/erpLogin', name: '网上管家婆登录', component: () => import("../views/h5/login/SHLJErpLogin.vue")},
];
const routes = baseRouters.concat(WebRouter, QywxMobileRouter, SaasPlatRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
export default router
