<template>
  <!-- 列表数据封装，用于统一样式处理 -->
  <div :style="{height: height, '--itemDisplay': itemDisplay, '--itemFloat': itemFloat, '--itemWidth': itemWidth}">
    <div :class="hasCategory? 'table-list table-category': 'table-list'">
      <div class="category">
        <slot name="category" @change="changeCategory"/>
      </div>
      <div class="table">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>

// 在list页面显示table，使用height=100%自动计算table高度
export default {
  props: {
    // 是否显示分页数据
    showPage: {default: true},
    // 一行显示几个el-form-item
    itemCount: {default: 1}
  },
  watch: {
    itemCount: {
      handler() {
        this.init();
      }
    }
  },
  name: "",
  components: {
  },
  data() {
    return {
      height: "calc(100% - 80px)",
      // 是否有分类
      hasCategory: false,
      itemDisplay: "block",
      itemFloat: "none",
      itemWidth: "100%",
    };
  },
  created() {
    this.height = "100%";
    this.init();
  },
  mounted() {
    // 看是否有上下文其它数据
    let brothers = this.$parent.$children;
    let brotherCount = 0;
    for (let i = 0; i < brothers.length; i++) {
      if (brothers[i]._uid !== this._uid) {
        // 在前面有组件
        brotherCount++;
      }
    }
    if (brotherCount > 2) {
      brotherCount = 2;
    }
    if (brotherCount) {
      this.height = "calc(100% - " + (brotherCount * 40) + "px)";
    } else {
      this.height = "100%";
    }
    // 绑定slot事件
    const obs = new MutationObserver(this.changeCategory);
    obs.observe(this.$el, {
      childList: true,
      subtree: true
    });
  },
  methods: {

    init() {
      // 如果添加了category这个slot
      this.hasCategory = (this.$slots.category && this.$slots.category.length > 0);
      if (this.itemCount === 2) {
        this.itemDisplay = "inline-block"
        this.itemFloat = "left";
        this.itemWidth = "50%";
      } else if (this.itemCount === 3) {
        this.itemDisplay = "inline-block"
        this.itemFloat = "left";
        this.itemWidth = "33%";
      }
    },
    changeCategory() {
      this.init();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/tree.scss";
.table-list{
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  .category{
    display: none;
  }
  .table{
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  ::v-deep .description{
    font-size: 12px;
    color: #999;
  }
}
.table-category{
  padding-left: 270px;
  .category{
    display: block;
    height: 100%;
    width: 260px;
    padding: 10px;
    border: 1px solid #f1f1f1;
    overflow: auto;
    top: 0;
    left: 0;
    position: absolute;
    box-sizing: border-box;
  }
  .table{
    width: 100%;
    overflow: hidden;
  }
}
::v-deep .el-table .cell{
  height: 23px;
}
// 滚动条
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 8px; // 横向滚动条
  height: 8px; // 纵向滚动条 必写
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #409eff;
  border-radius: 6px;
}
::v-deep .el-table__fixed, ::v-deep .el-table__fixed-right{
  height: calc(100% - 10px) !important;
}
.table-list ::v-deep .el-form{
  overflow: hidden;
}
.table-list ::v-deep .el-form-item--small.el-form-item{
  display: var(--itemDisplay);
  width: var(--itemWidth);
  margin-bottom: 10px;
  float: var(--itemFloat);
  min-height: 36px;
  //margin: 0;
  //border: 1px solid silver;
  //box-sizing: border-box;
}
//::v-deep .el-form::after{
//  content: "";
//  clear: both;
//}
</style>